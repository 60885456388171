import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import ScrollLabel from '../components/scrollLabel/scrollLabel.jsx'

import './layout.css'
import { BrowserView } from 'react-device-detect'
import { withPrefix } from 'gatsby'

const mainStyle = {
  width: '100%',
  boxSizing: 'border-box',
}

const Layout = React.forwardRef((props, ref) => (
  <div>
    <Helmet>
      <html lang="en" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={withPrefix('/apple-touch-icon.png')}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={withPrefix('/favicon-32x32.png')}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={withPrefix('/favicon-16x16.png')}
      />
      <link rel="manifest" href={withPrefix('/site.webmanifest')} />
      <link
        rel="mask-icon"
        href={withPrefix('/safari-pinned-tab.svg')}
        color="#f84525"
      />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />

      <script>
        {`(function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    "gtm.start": new Date().getTime(),
                    event: "gtm.js"
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, "script", "dataLayer", "GTM-MFFHMZB");`}
      </script>

      <script
        async
        src="https://ga.clearbit.com/v1/ga.js?authorization=pk_032378a3504382bddaca44c6c9881441"
      />

      <script>
        {`!function(){var clearbit=window.clearbit=window.clearbit||[];if(!clearbit.initialize)if(clearbit.invoked)window.console&&console.error&&console.error("Clearbit snippet included twice.");else{clearbit.invoked=!0;clearbit.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","page","once","off","on"];clearbit.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);clearbit.push(e);return clearbit}};for(var t=0;t<clearbit.methods.length;t++){var e=clearbit.methods[t];clearbit[e]=clearbit.factory(e)}clearbit.load=function(t){var e=document.createElement("script");e.async=!0;e.src=("https://js.clearbit.com/v1/"+t+"/clearbit.min.js");var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};clearbit.SNIPPET_VERSION="3.1.0";
        clearbit.load('pk_032378a3504382bddaca44c6c9881441');
        clearbit.page();
        }}();`}
      </script>

      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-48349543-1"
      />
      <script>
        {`window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag("js", new Date());

            gtag("config", "UA-48349543-1");`}
      </script>

      <script>
        {`(function(i, s, o, g, r, a, m) {
                i["GoogleAnalyticsObject"] = r;
                (i[r] =
                    i[r] ||
                    function() {
                        (i[r].q = i[r].q || []).push(arguments);
                    }),
                    (i[r].l = 1 * new Date());
                (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(
                window,
                document,
                "script",
                "//www.google-analytics.com/analytics.js",
                "ga"
            );`}
      </script>

      <script>
        {`      var sf14gv = 31567;
      (function() {
          var sf14g = document.createElement("script");
          sf14g.type = "text/javascript";
          sf14g.async = true;
          sf14g.src =
              ("https:" == document.location.protocol
                  ? "https://"
                  : "http://") + "t.sf14g.com/sf14g.js";
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(sf14g, s);
      })();
      `}
      </script>
    </Helmet>

    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-MFFHMZB"
        height="0"
        width="0"
        title="gaNoscript"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>

    <script
      type="text/javascript"
      src="https://secure.hims1nice.com/js/150989.js"
    />

    <main style={mainStyle} ref={ref} className={props.theme}>
      {props.children}
      <BrowserView>
        <ScrollLabel theme={props.theme} visible={props.scrollLabelVisible} />
      </BrowserView>
    </main>

    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
    piAId = '660843';
    piCId = '1315';
    piHostname = 'pi.pardot.com';
    
    (function() {
      function async_load(){
        var s = document.createElement('script'); s.type = 'text/javascript';
        s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
        var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
      }
      if(window.attachEvent) { window.attachEvent('onload', async_load); }
      else { window.addEventListener('load', async_load, false); }
    })();
    `,
      }}
    />

  <script type="text/javascript"> 
  {`
  _linkedin_partner_id = "505012"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); </script>
  `}
  </script> 
  <script type="text/javascript"> 
  {`(function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})();`}
  </script>
  </div>
))

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    showHomepageLoading: state.showHomepageLoading,
    scrollLabelVisible: state.scrollLabelVisible,
  }
}

const ConnectedLayout = connect(
  mapStateToProps,
  null
)(Layout)

export default ConnectedLayout
